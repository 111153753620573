.handle__row {
    flex-wrap: wrap;
    margin: 0 -50px;
}

.handle__col {
    width: 100%;
    flex: 1;
    padding: 0 50px;
}

.ourWorks__contant {
    background-color: var(--color-ffffff);
    position: relative;
    flex-direction: column;
}

.ourWorks__contant.wrapper__container {
    padding: 0;
}

.ourWorks__wrapper {
    color: var(--color-282828);
    padding: 15% 0 5.3% 0;
    position: relative;
    height: initial;
}

.ourWorks__titleWrapper {
    display: flex;
    justify-content: center;
    height: 80px;
    .ourWorks__title {
        font-size: 2.5rem;
        font-weight: 500;
        height: 100%;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 60%;
            height: 5px;
            top: calc(100% - 5px);
            left: 20%;
            right: 20%;
            background-color: var(--color-f7941d);
        }
    }
}

.ourWorks__text {
    display: flex;
    justify-content: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-top: 34px;
    text-align: center;
    span {
        text-align: center;
        line-height: 40px;
    }
}

.ourWorks__infoWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 85px;
}

.ourWorks__infoBody {
    display: flex;
    justify-content: center;
}

.ourWorks__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    .info__img {
        width: 150px;
        img {
            width: 100%;
        }
    }
    .info__count {
        text-align: center;
        font-family: var(--font-family-roboto-bold);
        font-size: 3.75rem;
        line-height: 4.44rem;
        font-weight: 700;
        color: var(--color-282828);
        margin-top: 30px;
    }
    .info__title {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.7rem;
        margin-top: 10px;
    }
}

.ourWorks__helpsWrapper {
    width: 100%;
    min-width: 100%;
    position: absolute;
    left: 0;
    // bottom: calc(100% - 125px);
    top: -125px;
    z-index: 5;
}

.ourWorks__helpWrapper {
    display: flex;
    height: 250px;
}

.ourWorks__helpBody {
    height: 100%;
}

.help {
    height: 100%;
    width: 100%;
    // box-shadow: 5px 5px 25px #0000001A;
    border: 1px solid #0000001A;
    background-color: var(--color-ffffff);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 50;
    &.active {
        background-color: var(--color-7bc25a);
    }
    &:hover {
        animation-name: box__shadow;
        animation-duration: 300ms;
        animation-fill-mode: forwards;
    }
}

.help__body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10%;
    .help__img {
        width: 33%;
        margin: 0 auto;
    }
    .help__text {
        color: var(--color-7bc25a);
        font-size: 1.56rem;
        line-height: 1.875rem;
        margin-top: 25px;
        text-align: center;
        &.active {
            color: var(--color-ffffff);
        }
    }
}

.ourWorks__helps {
    position: relative;
    max-width: 1370px;
    width: 100%;
    margin: 0 auto;
    z-index: 100;
    .ourWorks__container {
        position: absolute;
        top: -125px;
        left: 0;
    }
    .ourWorks__helpsWrapper {
        display: flex;
    }
}

.ourWorks__container {
    width: 100%;

}
@include media-breakpoint-down('lg') {
    .ourWorks__helpWrapper {
        &.handle__row {
            margin: 0;
        }
        .handle__col {
            padding: 0 30px;
        }
    }
    .ourWorks__info {
        .info__title{
            font-size: 1rem;
        }
    }

}

@include media-breakpoint-down('md') {
    .container {
        // padding-left: 0;
        // padding-right: 0;
    }
    .ourWorks__titleWrapper{
        .ourWorks__title{
            font-size: 1.55rem;
        }
    }
    .help__body {
        .help__img {
            // width: auto;
            margin: 0 auto;
        }
        .help__text {
            display: none;
        }
    }
    .ourWorks__helpWrapper {
        height: 150px;
        &.handle__row {
            margin: 0;
        }
        .handle__col {
            padding: 0;
        }
    }
    .ourWorks__helps {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        .ourWorks__container {
            top: 0;
        }
    }
    .ourWorks__infoWrapper {
        margin-top: 30px;
        flex-direction: column;
        &.handle__row {
            margin: 0;
        }
        .handle__col {
            padding: 0;
        }
    }
    .ourWorks__text {
        text-align: center;
    }
    .ourWorks__infoBody {
        margin-top: 20px;
    }
    .ourWork__origin-container {
        width: 100%;
        max-width: 100%;
        &>div {
            margin-right: 0;
            margin-left: 0;
        }
    }
    .ourWorks__wrapper {
        padding: 25% 0 5.3% 0;
    }
}

@include media-breakpoint-down('sm') {
    .ourWorks__helpWrapper {
        height: 75px;
    }
    .ourWork__origin-container.container {
        padding-right: 0;
        padding-left: 0;
        &>div {
            margin: 0;
        }
    }
}

@include media-breakpoint-down('xs') {
    .ourWorks__helpWrapper {
        height: 75px;
    }
    .ourWork__origin-container.container {
        padding-right: 0;
        padding-left: 0;
        &>div {
            margin: 0;
        }
    }
    .ourWorks__wrapper {
        .row {
            margin: 0;
        }
    }
}