.call__wrapper {
  width: 238px;
  height: 110px;
  position: fixed;
  top: 260px;
  right: 0;
  z-index: 100;
  background-color: var(--color-7bc25a);
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
  cursor: pointer;
  padding: 20px 40px;
  overflow: hidden;
  &:hover {
    animation-name: call;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    -webkit-animation-name: call;
    -webkit-animation-duration: 300ms;
    -webkit-animation-fill-mode: forwards;
    .call__text-phonePart {
      animation-name: phoneVisibility;
      animation-duration: 10ms;
      animation-delay: 300ms;
      animation-fill-mode: forwards;
      -webkit-animation-name: phoneVisibility;
      -webkit-animation-duration: 300ms;
      -webkit-animation-delay: 300ms;
      -webkit-animation-fill-mode: forwards;
    }
    // .call {
    //     .call__text {
    //         span {
    //             display: block;
    //         }
    //     }
    // }
  }
  .call {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .call__img {
      flex: 0 0 60px;
      .call__image {
        .st0 {
          fill: #2c6adb;
        }
        width: 100%;
      }
    }
    .call__text {
      flex: 0 0 calc(100% - 75px);
      color: white;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-align: end;
      span {
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
}

.call__text-phonePart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0;
  visibility: hidden;
  .call__phoneImg {
    flex: 0 0 16px;
    margin-right: 5px;
    .call__phoneImage {
      .st3 {
        fill: white !important;
      }
    }
    // .call__phoneImage {
    //     width: 100%;
    //     fill: white;
    //     stroke: white;
    // }
  }
}

@keyframes call {
  0% {
    width: 238px;
  }
  100% {
    width: 315px;
  }
}

@-webkit-keyframes call {
  0% {
    width: 238px;
  }
  100% {
    width: 315px;
  }
}

@keyframes phoneVisibility {
  0% {
    height: 0;
    visibility: hidden;
  }
  100% {
    height: 100%;
    visibility: visible;
  }
}

@-webkit-keyframes phoneVisibility {
  0% {
    height: 0;
    visibility: hidden;
  }
  100% {
    height: 100%;
    visibility: visible;
  }
}

@include media-breakpoint-down("lg") {
  .call__wrapper {
    display: none;
  }
}
