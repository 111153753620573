.goal__contant {
    padding: 5.3% 0;
}

.goal__title {
    margin-bottom: 60px;
}

.subtitle__text {
    font-size: 1.56rem;
    line-height: 2.7rem;
    color: var(--color-313032);
    p {
        &:first-child {
            margin-bottom: 30px;
        }
        span {
            font-weight: 700;
        }
    }
}

@media (max-width: 992px) {
    .goal__title {
        margin-bottom: 20px;
    }
    .subtitle__text {
        font-size: 1rem;
        line-height: 1.6rem;
    }
}