@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?c82uvm');
  src:  url('../fonts/icomoon.eot?c82uvm#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?c82uvm') format('truetype'),
    url('../fonts/icomoon.woff?c82uvm') format('woff'),
    url('../fonts/icomoon.svg?c82uvm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Floopen .path1:before {
  content: "\e900";
  color: rgb(246, 246, 246);
}
.icon-Floopen .path2:before {
  content: "\e901";
  margin-left: -7.1328125em;
  color: rgb(143, 84, 161);
}
.icon-Floopen .path3:before {
  content: "\e902";
  margin-left: -7.1328125em;
  color: rgb(143, 84, 161);
}
.icon-Floopen .path4:before {
  content: "\e903";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path5:before {
  content: "\e904";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path6:before {
  content: "\e905";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path7:before {
  content: "\e906";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path8:before {
  content: "\e907";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path9:before {
  content: "\e908";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path10:before {
  content: "\e909";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path11:before {
  content: "\e90a";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path12:before {
  content: "\e90b";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path13:before {
  content: "\e90c";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path14:before {
  content: "\e90d";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path15:before {
  content: "\e90e";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path16:before {
  content: "\e90f";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path17:before {
  content: "\e910";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path18:before {
  content: "\e911";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path19:before {
  content: "\e912";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path20:before {
  content: "\e913";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path21:before {
  content: "\e914";
  margin-left: -7.1328125em;
  color: rgb(246, 246, 246);
}
.icon-Floopen .path22:before {
  content: "\e915";
  margin-left: -7.1328125em;
  color: rgb(203, 203, 202);
}
.icon-Floopen .path23:before {
  content: "\e916";
  margin-left: -7.1328125em;
  color: rgb(203, 203, 202);
}
.icon-Floopen .path24:before {
  content: "\e917";
  margin-left: -7.1328125em;
  color: rgb(203, 203, 202);
}
.icon-Floopen .path25:before {
  content: "\e918";
  margin-left: -7.1328125em;
  color: rgb(203, 203, 202);
}
.icon-Floopen .path26:before {
  content: "\e919";
  margin-left: -7.1328125em;
  color: rgb(203, 203, 202);
}
.icon-Floopen .path27:before {
  content: "\e91a";
  margin-left: -7.1328125em;
  color: rgb(203, 203, 202);
}
.icon-Floopen .path28:before {
  content: "\e91b";
  margin-left: -7.1328125em;
  color: rgb(203, 203, 202);
}
.icon-Floopen .path29:before {
  content: "\e91c";
  margin-left: -7.1328125em;
  color: rgb(203, 203, 202);
}
.icon-Floopen .path30:before {
  content: "\e91d";
  margin-left: -7.1328125em;
  color: rgb(203, 203, 202);
}
.icon-LInkdinIcon:before {
  content: "\e91e";
}
.icon-address:before {
  content: "\e91f";
  color: #7ac25b;
}
.icon-fb:before {
  content: "\e920";
  color: #fff;
}
.icon-trash:before {
  content: "\e921";
  color: #436ab2;
}
.icon-file:before {
  content: "\e922";
  color: #436ab2;
}
.icon-icons-07 .path1:before {
  content: "\e923";
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path2:before {
  content: "\e924";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path3:before {
  content: "\e925";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path4:before {
  content: "\e926";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path5:before {
  content: "\e927";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path6:before {
  content: "\e928";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path7:before {
  content: "\e929";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path8:before {
  content: "\e92a";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path9:before {
  content: "\e92b";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path10:before {
  content: "\e92c";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path11:before {
  content: "\e92d";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path12:before {
  content: "\e92e";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path13:before {
  content: "\e92f";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path14:before {
  content: "\e930";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path15:before {
  content: "\e931";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path16:before {
  content: "\e932";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path17:before {
  content: "\e933";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path18:before {
  content: "\e934";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path19:before {
  content: "\e935";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path20:before {
  content: "\e936";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path21:before {
  content: "\e937";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path22:before {
  content: "\e938";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path23:before {
  content: "\e939";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path24:before {
  content: "\e93a";
  margin-left: -5.2919921875em;
  color: rgb(0, 0, 0);
}
.icon-icons-07 .path25:before {
  content: "\e93b";
  margin-left: -5.2919921875em;
  color: rgb(17, 179, 125);
}
.icon-icons-07 .path26:before {
  content: "\e93c";
  margin-left: -5.2919921875em;
  color: rgb(177, 210, 53);
}
.icon-icons-07 .path27:before {
  content: "\e93d";
  margin-left: -5.2919921875em;
  color: rgb(19, 147, 70);
}
.icon-icons-06:before {
  content: "\e93e";
  color: #7ac25b;
}
.icon-icons-10:before {
  content: "\e93f";
  color: #fff;
}
.icon-icons-11:before {
  content: "\e940";
  color: #7ac25b;
}
.icon-icons-13 .path1:before {
  content: "\e941";
  color: rgb(122, 194, 91);
}
.icon-icons-13 .path2:before {
  content: "\e942";
  margin-left: -2.1064453125em;
  color: rgb(67, 106, 178);
}
.icon-icons-14 .path1:before {
  content: "\e943";
  color: rgb(122, 194, 91);
}
.icon-icons-14 .path2:before {
  content: "\e944";
  margin-left: -3.017578125em;
  color: rgb(255, 255, 255);
}
.icon-icons-14 .path3:before {
  content: "\e945";
  margin-left: -3.017578125em;
  color: rgb(67, 106, 178);
}
.icon-pdf .path1:before {
  content: "\e946";
  color: rgb(204, 86, 65);
}
.icon-pdf .path2:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(77, 77, 78);
}
.icon-pdf .path3:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path4:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path5:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icons-19 .path1:before {
  content: "\e94b";
  color: rgb(67, 106, 178);
}
.icon-icons-19 .path2:before {
  content: "\e94c";
  margin-left: -0.921875em;
  color: rgb(67, 106, 178);
}
.icon-icons-19 .path3:before {
  content: "\e94d";
  margin-left: -0.921875em;
  color: rgb(122, 194, 91);
}
.icon-icons-20 .path1:before {
  content: "\e94e";
  color: rgb(245, 149, 149);
}
.icon-icons-20 .path2:before {
  content: "\e94f";
  margin-left: -1.01953125em;
  color: rgb(238, 97, 97);
}
.icon-icons-20 .path3:before {
  content: "\e950";
  margin-left: -1.01953125em;
  color: rgb(238, 97, 97);
}
.icon-icons-20 .path4:before {
  content: "\e951";
  margin-left: -1.01953125em;
  color: rgb(248, 177, 177);
}
.icon-icons-20 .path5:before {
  content: "\e952";
  margin-left: -1.01953125em;
  color: rgb(248, 177, 177);
}
.icon-icons-20 .path6:before {
  content: "\e953";
  margin-left: -1.01953125em;
  color: rgb(243, 124, 124);
}
.icon-icons-20 .path7:before {
  content: "\e954";
  margin-left: -1.01953125em;
  color: rgb(238, 97, 97);
}
.icon-icons-20 .path8:before {
  content: "\e955";
  margin-left: -1.01953125em;
  color: rgb(238, 97, 97);
}
.icon-icons-20 .path9:before {
  content: "\e956";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-icons-20 .path10:before {
  content: "\e957";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-icons-20 .path11:before {
  content: "\e958";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-icons-20 .path12:before {
  content: "\e959";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-icons-20 .path13:before {
  content: "\e95a";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-icons-20 .path14:before {
  content: "\e95b";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-icons-20 .path15:before {
  content: "\e95c";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-icons-20 .path16:before {
  content: "\e95d";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-icons-20 .path17:before {
  content: "\e95e";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-icons-20 .path18:before {
  content: "\e95f";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-icons-20 .path19:before {
  content: "\e960";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-icons-20 .path20:before {
  content: "\e961";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-icons-20 .path21:before {
  content: "\e962";
  margin-left: -1.01953125em;
  color: rgb(0, 0, 0);
}
.icon-direct:before {
  content: "\e963";
  color: #7ac25b;
}
.icon-icons-22:before {
  content: "\e964";
}
.icon-user .path1:before {
  content: "\e965";
  color: rgb(122, 194, 91);
}
.icon-user .path2:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-user .path3:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icons-24:before {
  content: "\e968";
}
.icon-icons-25:before {
  content: "\e969";
  color: #7ac25b;
}
.icon-icons_09:before {
  content: "\e96a";
  color: #7ac25b;
}
.icon-icons_12:before {
  content: "\e96b";
  color: #436ab2;
}
.icon-icons_15 .path1:before {
  content: "\e96c";
  color: rgb(122, 194, 91);
}
.icon-icons_15 .path2:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icons_16 .path1:before {
  content: "\e96e";
  color: rgb(122, 194, 91);
}
.icon-icons_16 .path2:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icons_16 .path3:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icons_17 .path1:before {
  content: "\e971";
  color: rgb(122, 194, 91);
}
.icon-icons_17 .path2:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icons_17 .path3:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-logo .path1:before {
  content: "\e974";
  color: rgb(0, 0, 0);
}
.icon-logo .path2:before {
  content: "\e975";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path3:before {
  content: "\e976";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path4:before {
  content: "\e977";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path5:before {
  content: "\e978";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path6:before {
  content: "\e979";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path7:before {
  content: "\e97a";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path8:before {
  content: "\e97b";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path9:before {
  content: "\e97c";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path10:before {
  content: "\e97d";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path11:before {
  content: "\e97e";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path12:before {
  content: "\e97f";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path13:before {
  content: "\e980";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path14:before {
  content: "\e981";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path15:before {
  content: "\e982";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path16:before {
  content: "\e983";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path17:before {
  content: "\e984";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path18:before {
  content: "\e985";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path19:before {
  content: "\e986";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path20:before {
  content: "\e987";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path21:before {
  content: "\e988";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path22:before {
  content: "\e989";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path23:before {
  content: "\e98a";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path24:before {
  content: "\e98b";
  margin-left: -5.29296875em;
  color: rgb(0, 0, 0);
}
.icon-logo .path25:before {
  content: "\e98c";
  margin-left: -5.29296875em;
  color: rgb(20, 179, 125);
}
.icon-logo .path26:before {
  content: "\e98d";
  margin-left: -5.29296875em;
  color: rgb(178, 210, 51);
}
.icon-logo .path27:before {
  content: "\e98e";
  margin-left: -5.29296875em;
  color: rgb(14, 147, 25);
}
.icon-phone:before {
  content: "\e98f";
}