@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.footer-contaent {
  width: 100%;
}

.footer__up-infoText {
  margin-bottom: 20px;
  align-self: flex-end;
}

.footer__up-wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 43px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }
}

.footer__up {
  width: 100%;
  background-color: var(--color-f6f6f6);
}

.footer__up.wrapper__container,
.footer__down.wrapper__container {
  padding: 0 15px;
}

.footer__down {
  width: 100%;
  height: 54px;
  background-color: var(--color-313032);
}

.footer__up-info {
  flex: 0 0 240px;
  height: 100%;
}

.footer__up-menus {
  flex: 1 1 auto;
  align-self: stretch;
  .footer__up-menusWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.footer__up-logoWrapper {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  position: relative;
  img {
    width: 100%;
  }
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: calc(100% - 1px);
    left: 0;
    background-color: var(--color-cbcbcb);
  }
}

.footer__up-infoWrapper {
  position: relative;
  padding: 20px 0;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: calc(100% - 1px);
    left: 0;
    background-color: var(--color-cbcbcb);
  }
}

.footer__up-infoAdress {
  display: flex;
  justify-content: space-between;
  .footer__up-adressIcon {
    height: 30px;
    align-self: center;
    img {
      height: 100%;
    }
    .footer__up-adressIconImg {
      height: 100%;
    }
    .phoneIcon {
      width: 100%;
      height: 100%;
      fill: var(--color-7bc25a);
    }
    img {
      width: 26px;
    }
  }
  .footer__up-adressText {
    align-self: center;
    font-family: var(--font-family-montserrat-arm2);
    font-size: 0.7rem;
    line-height: 1.2rem;
    color: var(--color-4d4d4d);
    text-align: end;
  }
}

.footer__up-infoPhone {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  .footer__up-phoneIcon {
    width: 26px;
    height: auto;
    align-self: center;
    img {
      width: 100%;
    }
    .footer__up-phoneIconImg {
      width: 100%;
    }
    .phoneIcon {
      width: 100%;
      height: 100%;
      fill: var(--color-7bc25a);
    }
  }
  .footer__up-phoneText {
    align-self: center;
    font-family: var(--font-family-roboto-bold);
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.4rem;
    color: var(--color-4d4d4d);
    a {
      &:hover {
        color: unset;
      }
    }
  }
}

.footer__circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid var(--color-7bc25a);
}

.footer__up-socialIcons {
  display: flex;
  padding: 25px 0;
  .footer__up-icon {
    width: 35px;
    margin-right: 25px;
    img {
      width: 100%;
    }
  }
}

.footer__up-menusList {
  display: flex;
  justify-content: flex-end;
  max-width: 980px;
  ul {
    display: flex;
    align-items: center;
    padding: 0;
    & > div {
      display: flex;
      align-items: center;
      padding: 0;
      li {
        padding-right: 45px;
        position: relative;
        &::after {
          content: "";
          width: 1px;
          height: 100%;
          position: absolute;
          top: 0;
          left: calc(100% - 22px);
          background-color: var(--color-7bc25a);
          background-color: var(--color-7bc25a);
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
    .arrow__component {
      width: 208px;
      .arrow__component-body {
        background: var(--color-7bc25a) 0% 0% no-repeat padding-box;
        &::after {
          border-left: 22px solid var(--color-7bc25a);
        }
      }
    }
  }
}

.footer__down-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  .footer__down-info {
    color: var(--color-cbcbcb);
    font-size: 14px;
  }
  .footer__down-logo {
    // width: 145px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: white;
    p {
      white-space: nowrap;
      font-size: 14px;
      color: var(--color-cbcbcb);
      a {
        font-size: 18px;
        span {
          font-weight: 700;
          color: white;
          span {
            color: #8e52a1;
          }
        }
      }
    }
  }
}

.footer__up-logosWrapper {
  width: 100%;
  // flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.footer__up-logos {
  display: flex;
  // align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 980px;
  // padding-left: 155px;
  .footer__up-infoText {
    // margin-bottom: 20px;
    // height: 36px;
    // padding-bottom: 10px;
    position: relative;
    text-align: center;
    & > div {
      display: flex;
      align-items: center;
      height: 100%;
      // text-align: center;
    }
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--color-cbcbcb);
    }
  }
  .footer__up-logoOne {
    margin-right: 20px;
    img {
      // width: 52px;
    }
  }
  .footer__up-logoTwo {
    height: 49px;
    img {
      height: 100%;
    }
  }
}

.footer__up__down-arrow.arrow__component {
  .arrow__component-body {
    &::before {
      border-left: 22px solid var(--color-f6f6f6);
    }
  }
}

.footer__up-logos {
  padding-left: 50px;
}

// @include media-breakpoint-down("xl") {
//   .all__container {
//     max-width: 1140px;
//     width: 100%;
//     margin-left: 15px;
//     margin-right: 15px;
//   }
// }

@include media-breakpoint-down("xl") {
  .footer__up-menusList {
    ul {
      &>div {
        li {
          a {
            span {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
  .footer__up-logos {
    .footer__up-infoText {
      margin-bottom: 20px;
      font-size: 0.8rem;
    }
  }
}

@include media-breakpoint-down("lg") {
  .footer__up-logos {
    .footer__up-infoText {
      font-size: 11px;
    }
  }
  .footer__up-menusList {
    ul {
      & > div {
        li {
          &::after {
            left: calc(100% - 7.5px);
          }
        }
      }
    }
  }
}

@include media-breakpoint-down("md") {
  .header__up-wrapper {
    justify-content: space-between;
  }
  .headder__down-menus {
    height: 120px;
  }
  .header__container {
    flex-direction: column;
  }
  .footer__up-infoText {
    margin-bottom: 0;
    margin-top: 20px;
  }
}

@include media-breakpoint-down("lg") {
  .footer__up-logos {
    .footer__up-logoOne {
      img {
        // width: 40px;
      }
    }
    .footer__up-logoTwo {
      width: 368px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .footer__up-menusList ul > div li {
    padding-right: 15px;
  }
}
@media (max-width: 992px) {
  .header-cart-nav {
    flex-direction: column;
  }
}

@media (max-width: 555px) {
}

@include media-breakpoint-down("md") {
  .footer__up-wrapper {
    flex-direction: column;
    padding: 0 15px;
  }
  .footer__up-socialIcons {
    justify-content: center;
  }
  .footer__up-infoAdress {
    justify-content: center;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    .footer__up-adressText {
      font-size: 14px;
      text-align: center;
    }
  }

  .footer__up-wrapper {
    flex-direction: column;
    padding: 0 15px;
  }
  .footer__up-logoWrapper {
    text-align: center;
    padding-top: 60px;
    img {
      width: 200px;
    }
  }
  .footer__up-socialIcons {
    justify-content: center;
  }
  .footer__up-infoAdress {
    justify-content: space-between;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
  }
  .footer__up-infoPhone {
    justify-content: center;
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .footer__up-menusList {
    width: 100%;
    justify-content: center;

    ul {
      div {
        display: block;
      }
      flex-direction: column;
      li {
        padding-right: 0;
        width: 100%;
        text-align: center;
        padding: 8px 10px;
        border-bottom: 1px solid var(--color-cbcbcb);
        margin-bottom: 10px;
        &:after {
          display: none;
        }
      }
    }
  }
  .footer__up-logos {
    padding-left: 0;
    flex-direction: column;
    text-align: center;
    padding: 0;
    div {
      padding-top: 15px;
    }
  }
  .footer__up-infoText {
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;
      span {
        font-size: 1rem;
      }
    }
  }
  .footer__up-logos {
    padding-bottom: 0;
    .footer__up-logoTwo {
      width: 100%;
    }
  }
  .footer__down {
    .container {
      max-width: 100%;
      width: 100%;
    }
  }
  .footer__down-wrapper {
    flex-direction: column;
    justify-content: center;
  }
}

// @media (min-width: 992px) and (max-width: 1199px) {
//   .footer__up-info {
//     flex: 0 0 160px;
//   }
//   .arrow__component .arrow__component-body {
//     font-size: 0.9rem;
//   }
//   .footer__up-menusList ul .arrow__component {
//     width: 180px;
//   }
//   .footer__up-menusList {
//     ul {
//       li {
//         padding-right: 30px;
//         a {
//           font-size: 11px;
//         }
//       }
//     }
//   }
//   .footer__up.wrapper__container,
//   .footer__down.wrapper__container {
//     padding: 0 15px;
//   }
//   .footer__up-infoPhone .footer__up-phoneText {
//     font-size: 1rem;
//   }
//   .footer__up-infoText {
//     height: 50px;
//   }
// }
