.reservedPoints__contant-title {
    padding: 5.3% 0 0 0;
}

.reservedPoints__contant-map {
    height: 100vh;
    width: 100%;
    // img {
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    // }
}

.reservedPoints__contant-address {
    padding: 60px 0 5.3% 0;
}

.reservedPoints__contant-address {
    table {
        border-collapse: collapse;
        width: 100%;
        font-family: var(--font-family-montserrat-arm);
        font-size: 1.56rem;
        line-height: 2.125rem;
        color: var(--color-4d4d4d);
        tr {
            height: 100px;
        }
        td {
            border: 1px solid var(--color-b5b5b5);
            &:first-child {
                width: 30%;
                text-align: center;
            }
            &:last-child {
                width: 70%;
            }
        }
    }
}

.reservedPoints__address {
    display: flex;
    align-items: center;
    margin-left: 6%;
    .reservedPoints__address-img {
        height: 30px;
        .reservedPoints__address-image {
            height: 100%;
        }
    }
    .reservedPoints__address-info {
        margin-left: 2%;
        p {
            &:first-child {
                font-size: 0.875rem;
                line-height: 1.125rem;
                color: var(--color-7bc25a);
            }
            &:last-child {
                font-size: 1.125rem;
                line-height: 1.375rem;
                font-weight: 700;
            }
        }
    }
}

@media (max-width:992px) {
    .reservedPoints__contant-address {
        padding: 30px 0 5.3% 0;
    }
    .reservedPoints__contant-address {
        table {
            font-size: 1rem;
            line-height: 1.5rem;
            tr {
                height: 60px;
            }
            td {

            }
        }
    }
    .reservedPoints__address {
        .reservedPoints__address-info {
            p {
                &:first-child {
                    font-size: 0.6rem;
                    line-height: 1rem;
                }
                &:last-child {
                    font-size: 0.9rem;
                    line-height: 1.1rem;
                }
            }
        }
    }
}