.contacts__contant {
    .arrow__component {
        width: 191px;
    }
}

.contacts__body{
    .form-footer{
        padding-top: 30px;
    }
}
.contacts__infoWrapper {
    height: 100%;
}

.contacts__theLast .form-group {
    margin-bottom: 0;
}

.contacts__infoItem {
    border-top: 1px solid var(--color-cbcbcb);
    display: flex;
    align-items: center;
    height: 100%;

    &.active {
        border-bottom: 1px solid var(--color-cbcbcb);
    }
}

.contacts__infoImg {
    width: 30px;
    margin-right: 20px;
    img {
    }
}

.contacts__infoBody {
    p {
        font-size: 1rem;
        font-weight: 500;
        color: var(--color-7bc25a);
        span {
            color: var(--color-4d4d4d);
            font-weight: 900;
        }
    }
}

@include media-breakpoint-down('sm') {
    .contacts__infoWrapper{
        padding-bottom: 30px;
    }
    .contacts__infoItem{
        padding: 3px 0;
    }
}