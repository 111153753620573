.partners__contant {
    padding: 5.3% 0;
}

.partners__wrapper {

}

.partners__title {
    margin-bottom: 60px;
}

.titleOne {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3rem;
    color: var(--color-282828);
    padding-bottom: 20px;
    margin-bottom: 60px;
    .titleOne__wrapper {
        span {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: calc(100% + 15px);
                left: 0;
                width: 200px;
                height: 5px;
                background-color: var(--color-7bc25a);
            }
        }
    }
    &.yellow {
        .titleOne__wrapper {
            span::after {
                background-color: var(--color-f7941d);
            }
        }
    }
}

.partners__wrapper {

}

.partners__bodyWrapper {
    .kyJvRZ, .bTybGL {
        background-color: unset;
        box-shadow: unset;
        outline: none;
        color: var(--color-cbcbcb);
    }
    .bTybGL {
        &:hover:enabled, &:focus:enabled {
            background-color: unset;
            box-shadow: unset;
            color: var(--color-7bc25a);
        }
    }
}

.partners__item {
    height: 100px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

@media (max-width: 992px) {
    .titleOne {
        margin-bottom: 20px;
        justify-content: center;
        font-size: 23px;
        .titleOne__wrapper {
            text-align: center;
            span {
                position: relative;
                display: block;
                &::after {
                    width: 100%;
                    height: 4px;
                }
            }
        }
    }
}
@include media-breakpoint-down('lg') {
    .titleOne {
        .titleOne__wrapper {
            span {
                &::after {
                    top:100%;
                }
            }
        }
    }
}

@include media-breakpoint-down('xs') {
    .titleOne {
        .titleOne__wrapper {
            font-size: 15px;
        }
    }
}