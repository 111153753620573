.testimonials__contant {
  padding: 5.3% 0;
}

.testimonials__title {
  margin-bottom: 60px;
}

.testimonials__info-wrapper {
  box-sizing: border-box;
  background-color: var(--color-f6f6f6);
  padding: 50px;
  margin-bottom: 50px;
}

.testimonials__info {
  .testimonials__info-item {
    &:last-child {
      .testimonials__info-wrapper {
        margin-bottom: 0;
      }
    }
  }
}

.testimonials__info-body {
  height: auto;
  display: flex;
}

.testimonials__info-imgPart {
  flex: 0 0 24%;
  display: flex;
  align-items: center;
  & > div {
    height: 250px;
    width: 250px;
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.testimonials__info-Part {
  flex: 1 1 76%;
}

.testimonials__info-imgErapper {
  height: 16%;
  margin-bottom: 25px;
  .testimonials__info-img {
    height: 100%;
  }
}

.testimonials__info-textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 75px);
  color: var(--color-4d4d4d);
}

.testimonials__info-text {
  font-size: 1.125rem;
  line-height: 1.7rem;
  font-weight: 500;
}

.testimonials__info-names {
  padding-top: 20px;
}

@include media-breakpoint-down("xl") {
    .testimonials__info-imgPart {
        flex: 0 0 24%;
        display: flex;
        align-items: center;
        margin-right: 30px;
      }
  .testimonials__info-imgErapper {
    height: unset;
  }
  .testimonials__info-textWrapper {
    height: unset;
  }
}

@media (max-width: 992px) {
  .testimonials__info-body {
    height: auto;
    flex-direction: column;
  }
  .testimonials__info-wrapper {
    padding: 20px;
    margin-bottom: 30px;
  }
  .testimonials__info-imgErapper {
    display: none;
  }
  .testimonials__info-imgPart {
    flex: 0 0 24%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .testimonials__info-text {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    margin-bottom: 20px;
  }
}
