.csoh__contant {
    background-color: var(--color-f6f6f6);
    padding: 100px 0;
}

.csoh__titleWrapper {
    display: flex;
    justify-content: center;
    height: 80px;
    .csoh__title {
        font-size: 2.5rem;
        font-weight: 500;
        height: 100%;
        position: relative;
        text-align: center;
        &::after {
            content: "";
            position: absolute;
            width: 60%;
            height: 5px;
            top: calc(100% - 5px);
            left: 20%;
            right: 20%;
            background-color: var(--color-7bc25a);
        }
    }
}

.csoh__text {
    color: var(--color-313032);
    font-family: var(--font-family-montserrat-arm);
    font-size: 1.56rem;
    line-height: 40px;
    text-align: center;
    margin-top: 25px;
}

.csoh__button {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    .arrow__component {
        width: 208px;
        .arrow__component-body {
            &::before {
                border-left: 22px solid var(--color-f6f6f6);
            }
        }
    }
}

@include media-breakpoint-down('md') {
    .csoh__contant {
        padding: 30px 0;
    }

    .csoh__titleWrapper {
        // height: auto;
        .csoh__title {
            font-size: 1.55rem;
            text-align: center;
        }
    }
    .csoh__text {
        font-size: 1.2rem;
        line-height: 1.7rem;
    }
    .csoh__button {
        margin-top: 30px;
    }
}

@include media-breakpoint-down('xs') {

    .csoh__titleWrapper {
        // height: auto;
        .csoh__title {
            font-size: 1.3rem;
            text-align: center;
        }
    }
}
