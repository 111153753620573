.socialWorker__contant {
    padding: 5.3% 0;
}

.socialWorker__title {
    margin-bottom: 60px;
}

.socialWorker__menusType {
    margin-bottom: 60px;
}

.socialWorker__menuWrapper {
    margin: 0 -35px;
    color: var(--color-4d4d4d);
    display: flex;
    .active {
        color: var(--color-7bc25a);
    }
    p {
        padding: 0 35px;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: calc(100% - 0.5px);
            width: 1px;
            height: 100%;
            background-color: var(--color-7bc25a);
        }
        span {
            cursor: pointer;
            white-space: nowrap;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        &:hover {
            color: var(--color-7bc25a);
        }
    }
}

.socialWorker__contant {
    .pdfItem__wrapper {
        cursor: default;
        pointer-events: none;
        box-shadow: 5px 5px 25px #0000001a;
    }
}

@media (max-width: 992px) {
    .socialWorker__menusType {
        margin-bottom: 20px;
    }
    .socialWorker__menuWrapper {
        flex-direction: column;
        margin: 0;
        p {
            padding: 0;
            padding-left: 20px;
            font-size: 1.2rem;
            line-height: 1.5rem;
            margin-bottom: 10px;
            margin-top: 10px;
            position: relative;
            &::after {
                left: 0;
                width: 2px;
            }
            &:last-child {
                &::after {
                    display: block;
                }
            }
        }
    }
}