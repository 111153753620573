.links__contant {
    padding: 5.3% 0;
}

.links__title {
    margin-bottom: 60px;
}

.linksCards__wrapper {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-of-type(3) {
        .linksCards__body {
            margin-bottom: 0;
        }
    }
}

.linksCards__body {
    height: 300px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 3px solid var(--color-f6f6f6);
    padding: 8%;
    margin-bottom: 50px;
    cursor: pointer;
    &:hover {
        animation-name: box__shadow;
        animation-duration: 300ms;
        animation-fill-mode: forwards;
    }
}

.linksCards__icon {
    height: 50%;
    display: flex;
    justify-content: center;
    .linksCards__img {
        height: 100%;
    }
    img {
        height: 100%;
    }
}

.linksCards__titleWrapper {
    height: 50%;
    color: var(--color-4d4d4d);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
}

.linksCards__title {
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
}

.linksCards__email {
    font-size: 0.9rem;
}

.links__arrowItem {
    height: 44px;
    margin: 0 auto;
    &.arrow__component {
        width: 251px;
    }
}

@keyframes box__shadow {
    0% {
        box-shadow:unset;
    }
    100% {
        box-shadow: 5px 5px 25px #0000001A;
    }
}

@media (max-width: 992px) {
    .links__title {
        margin-bottom: 30px;
    }
    .links__cards {
        margin-bottom: 0;
    }
    .linksCards__wrapper {
        margin-bottom: 30px;
    }
}