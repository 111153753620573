.places__contant {
  flex-direction: column;
  .places__up {
    background-color: var(--color-2c6adb);
    width: 100%;
    padding: 5.3% 0;
  }
  .places__down {
    width: 100%;
  }
}

.places__contant.wrapper__container {
  padding: 0;
}

.places__upWrapper {
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3rem;
  color: var(--color-ffffff);
}

.places__upTitle {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 130%;
    left: 25%;
    width: 50%;
    height: 5px;
    background-color: var(--color-7bc25a);
  }
}

.places__downWrapper {
  width: 100%;
  height: 100%;
}

.places__map {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}

@include media-breakpoint-down("xs") {
  .places__upTitle {
    position: relative;
    span {
        font-size: 18px;
        text-align: center;
    }
  }
}

@media (max-width: 992px) {
  .places__upWrapper {
    font-size: 1.55rem;
  }
}
