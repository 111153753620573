.wrapper__container {
  display: flex;
  justify-content: center;
  padding: 5.3% 0;
}
.header__up {
  background: #7cc35b 0% 0% no-repeat padding-box;
  width: 100%;
  height: 45px;
  padding: 0;
}
.header__down-container {
  height: 100%;
}
.header__up-wrapper {
  height: 100%;
  align-items: center;
  font-family: var(--font-family-roboto-bold);
  color: var(--color-ffffff);
  font-weight: 700;
  display: flex;
  justify-content: flex-end;
  & > div {
    display: flex;
    align-items: center;
  }
}
.header__up-phoneIcon {
  width: 15px;
  .phoneIcon {
    width: 100%;
    fill: var(--color-ffffff);
  }
  img {
    width: 100%;
  }
}
.burger-menu {
  display: none;
}
.header__up-phone {
  margin-left: 10px;
  font-size: 0.9rem;
  line-height: 0.85rem;
  a {
    color: white;
    &:hover {
      color: unset;
    }
  }
}
.circle {
  width: 28px;
  height: 28px;
  border: 1px solid var(--color-ffffff);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__up-fbIcon {
  width: 23px;
  margin-left: 32px;
  margin-right: 12px;
  img {
    width: 100%;
  }
  .header__up-FBimg {
    width: 100%;
    // fill: var(--color-ffffff);
  }
}
.header__up-linkedinIcon {
  width: 23px;
  img {
    width: 100%;
  }
  .header__up-linkedinIconImg {
    width: 100%;
  }
}
.app-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 997;
  .brand-logo {
    max-height: $header-height - 16px;
    max-width: 200px;
  }
  .navbar {
    min-height: $header-height;
    display: flex;
    align-items: center;
  }
  // .navbar-nav {
  //   align-self: stretch;
  // }
  .nav-item {
    position: relative;
    .nav-link {
      white-space: nowrap;
      font-size: 15px;
      line-height: 1.2;
      display: block;
      color: #303032;
      cursor: pointer;
      font-family: $font-family-base;
      padding: 31px 40px;
      .nav {
        display: none;
        border-top: 3px solid var(--color-7bc25a);
        position: absolute;
        left: 0;
        top: 100%;
        background-color: var(--color-ffffff);
        min-width: 374px;
        .nav-item {
          padding: 5px;
          .nav-link {
            color: var(--color-4d4d4d);
            font-size: 15px;
            line-height: 2.6;
            padding: 0 22px;
            display: block;
            &:hover {
              background: #f6f6f6;
              color: var(--color-7bc25a);
            }
          }
        }
        // min-height: 200px;
      }
    }
    &:hover .nav {
      display: block;
    }
  }
  .nav-link {
    display: flex;
    align-items: center;
    height: 100%;
    margin-bottom: -1px;
    &.active {
      box-shadow: inset 0 -2px 0 $primary;
    }
  }
  .backdrop {
    display: none;
  }
}
.header-content {
  .arrow__component-body {
    width: 161px;
  }
}

.header__up-phoneIcon {
  width: 15px;
  .call__phoneImage {
    fill: white;
    .st3 {
      fill: white !important;
    }
  }
}

@include media-breakpoint-down("xl") {
  .app-header {
    .nav-item {
      .nav-link {
        padding: 31px 18px;
      }
    }
  }
}
@include media-breakpoint-down("lg") {
  .app-header {
    .nav-item {
      .nav-link {
        font-size: 13px;
        padding: 31px 10px;
      }
    }
  }
}

@include media-breakpoint-down("md") {
  .app-header {
    .navbar {
      padding-top: 0.5rem;
      min-height: $header-height-sm;
    }
    .container {
      flex-wrap: nowrap;
      flex-direction: column;
    }
    .burger-menu {
      display: block;
      position: relative;
    }
    .navbar-brand {
      span {
        flex: 1;
        min-width: 0;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .brand-logo {
      max-height: $header-height-sm - 16px;
      max-width: $header-height-sm - 16px;
      // width: auto;
      // height: $header-height-sm - 16px;
    }
    .header-cart-nav {
      margin-left: auto;
    }
    .navbar-collapse {
      position: fixed;
      z-index: 99;
      top: $header-height-sm;
      bottom: 0;
      left: 0;
      width: 80%;
      background-color: #fff;
      transform: translateX(-100%);
      transition: transform 0.4s;
      &.show {
        transform: translateX(0);
      }
    }
  }

}
@include media-breakpoint-down("md") {
  .app-header {
    .container {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
    .navbar {
      background-color: var(--color-f6f6f6) !important;
      padding: 0;
      padding-bottom: 10px;
    }
    .nav-item {
      .nav-link {
        padding: 20px;
        font-weight: 700;
      }
    }
    .arrow__component .arrow__component-body::before {
      border-left: 22px solid var(--color-f6f6f6);
    }
    .navbar-nav {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 100%;
      width: 100%;
      z-index: 3;
      top: 95px;
      background: var(--white-two);
      transition: all 0.3s;
      &.active {
        right: 0;
        transition: all 0.3s;
      }
    }
    .logo-content {
      width: 100%;
      padding: 20px 15px;
      align-items: center;
      display: flex;
      background-color: var(--color-ffffff);
      margin-right: 0;
      justify-content: space-between;
    }
    .navbar-brand {
    }
    .nav-item {
      .nav-link {
        .nav {
          position: inherit;
          border: 0;
          background-color: var(--color-f6f6f6);
          margin: 10px -15px 0;
          box-shadow: none;
          .nav-item {
            padding: 0;
            border-bottom: 1px solid var(--color-ffffff);
            .nav-link {
              font-size: 13px;
              padding: 10px 35px;
            }
          }
        }
      }
    }
  }
  .app-header {
    .navbar {
      padding-bottom: 0;
    }
    .arrow__component {
      display: none;
    }
  }
  .header__up-wrapper {
    &>div {
      flex: 0 0 100%;
      justify-content: space-between;
    }
  }
  .header__up {
    .container {
      width: 100%;
      max-width: 100%;
      padding: 0 15px;
      &>div {
        margin-right: 0;
        margin-left: 0;
        &>div {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .header__up-socialIcons {
    &:last-child {
      &>div {
        margin-right: 0;
      }
    }
  }
}

@include media-breakpoint-down("xs") {
  .shadow-sm {
    min-width: 250px !important;
    li {
      a {
        span {
          white-space: break-spaces;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .header__up-wrapper {
    justify-content: space-between;
  }
}
