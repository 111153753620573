.organized-actions-item {
  margin-bottom: 50px;
}

.organized-item {
  // box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--white-two);
  padding: 0 0 31px;
  object-fit: contain;
  border-radius: 5px;
  overflow: hidden;
  // margin-bottom: 50px;
  &:hover {
    animation-name: box__shadow;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
  }
  .organized-item__image {
    width: 100%;
    height: 250px;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .item-content {
    padding: 0 31px;
    height: 330px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .item-content-wrapper {
      height: 100%;
      overflow: hidden;
      flex: 1 1 auto;
      .organized-item__description {
        // height: 100%;
        display: flex;
        align-items: flex-end;
      }
    }
    .item-content-button {
      margin-top: 10px;
      flex: 0 0 44px;
    }
    .item-date {
      height: 20%;
      display: flex;
      align-items: center;
      p {
        font-family: $font-family-base;
        // padding-top: 0.875rem;
        color: var(--azul);
        line-height: 1.5;
        font-size: 17px;
        font-weight: bold;
      }
      // padding-bottom: 34px;
    }
    .organized-textInfo {
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    h5 {
      // padding-top: 25px;
      font-family: $font-family-base;
      // font-size: 1.125rem;
      // line-height: 1.67;
      color: var(--greyish-brown);
      font-size: 19px;
      line-height: 25px;
      font-weight: bold;
    }
    p {
      color: var(--greyish-brown);
      line-height: 2;
      font-size: 0.937rem;
      // margin-top: 20px;
    }
  }
}
.item-button {
  display: flex;
  padding-top: 15px;
}

.organized-actions-item {
  &:nth-last-child(1),
  &:nth-last-child(2),
  &:nth-last-child(3) {
    margin-bottom: 0;
    .organized-item {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-down("md") {
  .organized-item {
    .item-content {
        height: 450px;
        .item-content-wrapper {
            padding-bottom: 30px;
        }
    }
  }
}

@media (max-width: 992px) {
  .organized-actions-item {
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    &:nth-last-child(2),
    &:nth-last-child(3) {
      margin-bottom: 50px;
    }
  }
  .organized-item {
    padding: 0;
  }
  .organized-item {
    .item-content {
      .item-date {
        p {
          padding-top: 0;
        }
      }
    }
  }
}
