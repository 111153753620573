.siteMap {
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-weight: 700;
    color: var(--color-4d4d4d);
}

.siteMap__secondItem-body {
    margin-bottom: 20px;
    width: 500px;
    position: relative;
}

.siteMap__secondItem-arrow {
    top: 0;
    right: 0;
    height: 100%;
    width: 30px;
    background-color: red;
}

.siteMap__thirdItem-body {
    font-size: 1.3rem;
    padding-left: 30px;
    margin-bottom: 15px;
    span {
        &:hover {
            color: var(--color-7bc25a);
        }
    }
}

.secondFooterItem {
    &:hover {
        color: var(--color-7bc25a);
    }
}

@media (max-width: 992px) {
    .siteMap {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    .siteMap__thirdItem-body {
        font-size: 1rem;
    }
}