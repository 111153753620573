.reports__title {
    margin-bottom: 60px;
}

.report__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    box-shadow: 5px 5px 25px #0000001A;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    padding-right: 40px;
    padding-left: 50px;
    overflow: hidden;
    font-size: 1.875rem;
    line-height: 2.3rem;
    font-weight: 500;
    color: var(--color-4d4d4d);
    position: relative;
    margin-bottom: 50px;
    cursor: pointer;
    p {
        position: relative;
        z-index: 10;
    }
    &:hover {
        color: var(--color-ffffff);
        .report__animation {
            animation-name: backgroundWidh;
            animation-duration: 100ms;
            animation-fill-mode: forwards;
        }
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 10px;
        background-color: var(--color-7bc25a);
    }
}

.report__animation {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10px;
    background-color: var(--color-7bc25a);
    z-index: 0;
}

.report__imgWrapper {
    height: 60%;
    position: relative;
    z-index: 10;
    img {
        height: 100%;
    }
    .report__img {
        height: 100%;
    }
}

@keyframes backgroundWidh {
    0% {
        width: 10px;
    }
    100% {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .reports__title {
        margin-bottom: 30px;
    }
    .report__wrapper {
        margin-bottom: 30px;
    }
}
@include media-breakpoint-down('md') {
    .report__wrapper {
        p{
            font-size: 15px;
        }
    }
    .report__imgWrapper{
        height: 40%;
    }
}