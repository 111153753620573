// @import '~react-datepicker/src/stylesheets/datepicker.scss';
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}
