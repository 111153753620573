.page-sale{
  padding-top: 94px;
}

.sale__image img {
  width: 100%;
  object-fit: cover;

}

.page-sale  .arrow__component {
  // width: 180px;
}