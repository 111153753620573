.singleNews__img {
    img {
        width: 100%;
        height: auto;
    }
}

.singleNews__date {
    color: var(--color-2c6adb);
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
}

.singleNews__text {
    padding-top: 30px;
    font-size: 1.2rem;
    line-height: 2rem;
}
.upComingAkcia__singlePage .item-button {
    padding-top: 40px;
}
.upComingAkcia__singlePage .arrow__component {
    width: 100%;
}

@media (max-width: 992px) {
    .singleNews__img {
        display: flex;
        justify-content: center;
        max-width: 600px;
    }
    .singleNews__date {
        text-align: center;
        padding-top: 30px;
    }
}

@include media-breakpoint-down('xs') {
    .singleNews__date {
        font-size: 18px;
    }
    
    .singleNews__text {
        font-size: 15px;
    }
}