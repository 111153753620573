.input{
  border: solid 1px var(--white);
  background-color: rgba(246, 246, 246, 0.5);
  border-radius: 5px;
  height: 45px;
  // padding: 13px 108.8px 13px 14px;
}
.label{
  color: var(--sage);
  font-family: $font-family-base;
  line-height: 1.21;
  font-weight: 500;
  font-size: 14px;
}
.text-wrong-label{
  color: var(--tangerine);
  font-weight: 500;
  line-height: 1.21;
  font-family: $font-family-base;
  font-size: 14px;
}
.form-group.text-danger {
  color: var(--tangerine) !important;
  .label{
  color: var(--tangerine) !important;
  }
  .invalid-feedback {
    display: none;
  }
  .is-invalid{
    border-color: var(--tangerine) !important;
    background-image: none !important;
  }
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
.form-footer{
  display: flex;
  justify-content: center;
  // margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .label{
    font-size: 13px;
  }
}
@media (min-width: 556px) and (max-width: 767px) {
  .label{
    font-size: 8px;
  }
}