.image-upload {
  position: relative;
  display: inline-block;
  margin-top: 26px;
  vertical-align: top;
  width: 160px;
  height: 119px;
  padding: 12px 30px;
  border-radius: 5px;
  border: dotted 2px #eaeaea;
  &.error{
    border-color: var(--tangerine)
  }
  &.lg {
    width: 100%;
    // height: auto;
    // padding-bottom: 130%;
  }
  .image-title{
    color: var(--greyish-brown);
    line-height: 1.21;
    font-family: $font-family-base;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    cursor: pointer;
    opacity: 0;
  }
  label {
    height: 100%;
    margin: 0;
    display: block;
  }
  span {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(white, 0.7);
    border-radius: 6px;
    transition: opacity 0.3s;
    &.empty {
      opacity: 1;
    }
    &.image-loading {
      opacity: 1;
    }
  }
  .preloader {
    height: auto;
  }
  &:hover {
    span {
      opacity: 1;
    }
  }
}

.delete-image {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 22;
}
.file{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .icon-file{
    display: flex;
    /* padding: 20px; */
    align-items: flex-end;
    height: 100%;
  }
}
.empty-value{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  img{
    position: inherit;
    width: 26px;
    display: inline-block;
    height: 33px;
  }
  p{
    padding-left: 20px;
    color: var(--greyish-brown);
    line-height: 1.21;
    font-size: 14px;
    margin: 0;
  }

}
.icon-files{
  padding-right: 10px;
  img{
    position: inherit;
    width: 20px;
    display: inline-block;
    height: 20px;
    object-fit: contain;
  }
}