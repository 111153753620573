.slider-contant {
  width: 100%;
  height: 750px;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
  }
}

.slider-contant.slider__home {
  &.active {
    .arrow__up-wrapper {
      display: none;
    }
    .slider__text {
      padding-bottom: 200px;
    }
  }
}

.slider__text {
  position: relative;
  z-index: 5;
  color: white;
  height: 100%;
  padding-bottom: 80px;
  display: flex;
  align-items: flex-end;
  p {
    font-size: 4rem;
    line-height: 4.5rem;
    font-weight: 700;
    width: 50%;
    padding-bottom: 30px;
  }
  span {
    font-size: 25px;
    line-height: 35px;
    font-weight: 400;
    display: block;
    width: 50%;
  }
}
@include media-breakpoint-down("xl") {
  .slider__text {
    p {
      font-size: 3.5rem;
      line-height: 4rem;
      font-weight: 700;
      width: 60%;
      padding-bottom: 30px;
    }
    span {
      font-size: 22px;
      line-height: 32px;
      font-weight: 400;
      display: block;
      width: 60%;
    }
  }
}

@include media-breakpoint-down("lg") {

}
@include media-breakpoint-down("md") {
  .slider-contant{
    height: 400px;
  }
  .slider__text p{
    width: 100%;
  }
  .slider__text{
    padding-bottom: 50px;
  }
}
@include media-breakpoint-down("md") {
  .slider-contant{
    height: 300px;
  }
  .slider__text p{
   font-size: 27px;
   line-height: 1.8;

  }
  .slider__text{
    padding-bottom: 50px;
  }
  .slider__text span{
    font-size: 22px;
    width: 100%;
    font-size: 18px;
  }
}

@include media-breakpoint-down("xs") {
  .handle__row25, .handle__row15 {
    margin: 0 -7.5px !important;
  }
  .handle__col25, .handle__col15 {
    padding: 7.5px !important;
  }
  .slider__text {
    p {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 700;
      width: 100%;
      padding-bottom: 30px;
    }
    span {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      display: block;
      width: 100%;
    }
  }
}