.blogAndNews__contant {
    padding: 5.3% 0;
    background-color: var(--color-f6f6f6);
}

.blogAndNews__title {
    color: var(--color-4d4d4d);
    padding-bottom: 20px;
    margin-bottom: 60px;
}

.handle__row25 {
    margin: 0 -25px;
}

.handle__col25 {
    padding: 0 25px;
}

.card {
    height: 560px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    cursor: pointer;
    &:hover {
        animation-name: box__shadow;
        animation-duration: 300ms;
        animation-fill-mode: forwards;
    }
}

.blogAndNews__card {
    margin-bottom: 0;
}

.card__img {
    flex: 0 0 45%;
    width: 100%;
    position: relative;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.card__infoWrapper {
    flex: 0 0 55%;
    padding: 25px;
    position: relative;
    &>div {
        height: 100%;
        width: 100%;
        &>div {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}

.card__infoTitle {
    color: var(--color-2c6adb);
    font-family: var(--font-family-montserrat-arm2);
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
    flex: 0 0 15%;
    span {
        font-weight: 700;
    }
}

.card__infoText {
    flex: 1 1 auto;
    color: var(--color-4d4d4d);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
        height: 60px;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.5rem;
    }
    .card__link {
        font-size: 0.875rem;
        line-height: 1.125rem;

    }
    span {
        font-size: 1rem;
    }
}

.card__infoLink {
    flex: 0 0 7%;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: end;
    a {
        color: var(--color-cbcbcb);
        text-decoration: underline;
    }
}

// .blogAndNews__cardsWrapper {
//     &:nth-last-child(1),
//     &:nth-last-child(2),
//     &:nth-last-child(3) {
//         .organized-item {
//             margin-bottom: 0;
//         }
//     }
// }

@media (max-width: 992px) {
    .blogAndNews__title {
        margin-bottom: 30px;
    }
}